<template>
  <div class="course">
    <div class="g-box">
      <div class="course-list">
        <div class="tip fl-ac">
          <div class="line"></div>
          <div class="txt">本学期课程</div>
        </div>
        <div class="content" v-loading="loading">
          <div class="item" v-for="(item, i) in courseList" :key="item.id">
            <div class="item-index">{{ String(i + 1).padStart(2, "0") }}</div>
            <div class="item-info fl-jc-sb">
              <div class="over-hd title">
                {{ item.name }}
              </div>
              <div class="title-ri fl-jc-sb">
                <div class="icon">
                  <img
                    class="img"
                    src="../../assets/img/icon/icon_eye.png"
                    alt=""
                  />
                </div>
                <div class="mount">
                  观看时长：{{ item.progress ? item.progress : 0 }}分钟
                </div>
                <div
                  v-if="item.progress > 0"
                  @click="toDetail(item)"
                  class="button contu fl-jc-ac"
                >
                  继续学习
                </div>
                <div v-else @click="toDetail(item)" class="button fl-jc-ac">
                  进入学习
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="course-list-now" v-if="studyList.length > 0">
        <div class="tip fl-ac">
          <div class="line"></div>
          <div class="txt">最近学习</div>
        </div>
        <div class="content fl-row fl-wrap">
          <div
            class="item"
            v-for="item in studyList"
            :key="item.lastKnowledgeId"
            @click="toDetail(item)"
          >
            <div class="time fl-ac">
              <div class="icon">
                <img
                  class="img"
                  src="../../assets/img/icon/icon_time.png"
                  alt=""
                />
              </div>
              <div class="txt">上次学到 {{ item.progress }} 分钟</div>
            </div>
            <div class="title">{{ item.name }}</div>
            <div class="konwledge fl-ac">
              <div class="icon">
                <img
                  class=""
                  src="../../assets/img/icon/icon_page.png"
                  alt=""
                />
              </div>
              <div class="txt over-hd">{{ item.lastKnowledge }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import page_mx from "@/mixin/page_mx";

export default {
  mixins: [page_mx],
  data() {
    return {
      courseList: [],
      studyList: [],
      loading: false,
    };
  },
  methods: {
    init() {
      this.loading = true;
      this.$api.ems.education.teachingProgram_listCourseForCurrentTerm
        .get({
          currentTerm: this.userInfo.currentTerm,
          majorId: this.userInfo.majorId,
          rollGrade: this.userInfo.rollGrade,
        })
        .then((res) => {
          this.courseList = res.data;
          this.loading = false;
        });
      this.$api.ems.education.teachingProgram_listStudyRecordForCurrentTerm
        .get({
          currentTerm: this.userInfo.currentTerm,
          majorId: this.userInfo.majorId,
          rollGrade: this.userInfo.rollGrade,
        })
        .then((res) => {
          this.studyList = res.data;
        });
    },
    toDetail(item) {
      this.$router.push({
        path: "./detail",
        query: {
          courseId: item.courseId,
          lastKnowledgeId: item.lastKnowledgeId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.course {
  display: flex;
  align-items: center;
  padding-top: 61px;
  padding-bottom: 41px;
  background-color: #f3f4f9;
  .tip {
    .line {
      width: 6px;
      height: 24px;
      background: linear-gradient(135deg, #19b6ff 0%, #1886ff 100%);
      border-radius: 6px;
    }
    .txt {
      padding-left: 10px;
      font-size: 24px;
      font-weight: 600;
      color: #2d3134;
    }
  }
  &-list {
    .item {
      margin-top: 20px;
      padding: 20px;
      height: 128px;
      background: #fff;
      border-radius: 8px;
      box-sizing: border-box;
      &-index {
        font-size: 24px;
        color: #cecfda;
        height: 32px;
        line-height: 32px;
      }
      &-info {
        .title {
          padding-top: 12px;
          width: 850px;
          font-size: 24px;
          font-weight: 500;
          color: #2d3134;
          height: 32px;
          line-height: 32px;
        }
        .title-ri {
          .icon {
            margin-right: 10px;
            width: 19px;
            height: 18px;
            .img {
              width: 100%;
              height: 100%;
            }
          }
          .mount {
            font-size: 14px;
            color: #858da4;
          }
          .button {
            margin-left: 20px;
            width: 96px;
            height: 36px;
            background: #1886ff;
            border: 1px solid #1886ff;
            box-shadow: 0px 2px 4px 0px rgba(24, 134, 255, 0.4);
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            cursor: pointer;
            user-select: none;
          }
          .button.contu {
            background: #fff;
            color: #1886ff;
            border: 1px solid #1886ff;
            box-shadow: none;
          }
        }
      }
    }
  }
  &-list-now {
    margin-top: 50px;
    .content {
      display: flex;
      .item {
        margin-top: 20px;
        margin-right: 20px;
        padding: 20px;
        width: 285px;
        height: 176px;
        border-radius: 8px;
        background-color: #fff;
        box-sizing: border-box;
        cursor: pointer;
        .time {
          padding: 4px;
          background-color: #f3f4f9;
          width: 140px;
          height: 26px;
          box-sizing: border-box;
          border-radius: 4px;
          .icon {
            width: 18px;
            height: 18px;
            .img {
              width: 100%;
              height: 100%;
            }
          }
          .txt {
            padding-left: 6px;
            font-size: 14px;
            color: #1886ff;
          }
        }
        .title {
          padding-top: 20px;
          height: 50px;
          font-size: 18px;
          font-weight: 500;
          color: #2d3134;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .konwledge {
          padding-top: 10px;
          .icon {
            width: 18px;
            height: 18px;
            .img {
              width: 100%;
              height: 100%;
            }
          }
          .txt {
            padding-left: 10px;
            font-size: 14px;
            color: #858da4;
          }
        }
      }
      .item:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
</style>
